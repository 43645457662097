<template>
  <v-container fluid fill-height class="bg">
    <v-container fluid pa-0>
      <v-row justify="center" align="center" class="mb-6">
        <brand></brand>
      </v-row>
      <v-row justify="center" align="center">
        <v-col md="6" cols="12" class="pa-0 d-flex justify-center">
          <v-card max-width="800px" width="100%" outlined elevation="12">
            <v-tabs v-model="state" color="primary" show-arrows grow>
              <v-tab>
                <v-icon color="secondary">
                  mdi-account-arrow-right-outline
                </v-icon>
                <small class="ml-2">Iniciar sessão</small>
              </v-tab>
              <v-tab>
                <v-icon color="secondary">mdi-account-plus-outline</v-icon>
                <small class="ml-2">Cadastro</small>
              </v-tab>
              <v-tab>
                <v-icon color="secondary">mdi-account-question-outline</v-icon>
                <small class="ml-2">Esqueci a senha</small>
              </v-tab>
            </v-tabs>

            <v-container fluid class="py-5">
              <v-tabs-items v-model="state">
                <v-tab-item>
                  <div class="accent--text text-center mb-4">
                    <b>Boas vindas!</b><br />
                    Preencha seu <b>email</b> e <b>senha</b> para iniciar a
                    sessão.<br />
                    Você também pode entrar usando sua conta <b>Google</b>.
                  </div>
                  <sign-in-form @change-state="changeState" />
                </v-tab-item>
                <v-tab-item>
                  <div class="accent--text text-center mb-4">
                    <b>Primeira vez na plataforma?</b><br />
                    Use seu <b>email</b> e escolha uma <b>senha</b> com no
                    <b>mínimo 6 caracteres</b> para iniciar seu cadastro.<br />
                    Você também pode entrar usando sua conta <b>Google</b>.
                  </div>
                  <sign-up-form />
                </v-tab-item>
                <v-tab-item>
                  <div class="accent--text text-center mb-4">
                    Será enviado no <b>email</b> um <b>link</b> para definição de uma
                    <b>nova senha</b>.<br />
                    Verifique sua caixa de <b>SPAM</b> caso não encontre o link na caixa de entrada.
                  </div>
                  <pass-reset />
                </v-tab-item>
              </v-tabs-items>
            </v-container>
            <v-divider></v-divider>
            <v-container class="accent--text text-center text-caption">
              <div>
                Precisa de ajuda? Visite nossa página de
                <a class="text-no-wrap" @click="$router.push('/faq')">
                  PERGUNTAS FREQUENTES
                </a>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import SignUpForm from "../components/auth/SignUpForm.vue";
import SignInForm from "../components/auth/SignInForm.vue";
import PassReset from "../components/auth/PassReset.vue";
export default {
  components: { SignUpForm, SignInForm, PassReset },
  data: () => ({
    state: 0,
  }),
  mounted() {
    let props = Object.keys(this.$route.query);
    if (props.indexOf("cadastro") > -1) {
      this.changeState(1);
    } else if (props.indexOf("esqueci") > -1 || props.indexOf("senha") > -1) {
      this.changeState(2);
    }
  },
  methods: {
    changeState(state) {
      this.state = state;
    },
  },
};
</script>