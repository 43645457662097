<template>
  <v-form v-model="valid" lazy-validation ref="form" @submit.prevent="submit">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="email"
            :rules="[required(email), emailRule(email)]"
            placeholder="Email"
            :disabled="loading"
            prepend-icon="mdi-at"
            required
            dense
            filled
            autocomplete
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="password"
            :rules="[required(password)]"
            placeholder="Senha"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            :disabled="loading"
            prepend-icon="mdi-lock-outline"
            required
            dense
            filled
            autocomplete
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center my-4 pa-1">
          <button
            class="mudadores-btn block"
            @click="submit"
            :disabled="loading"
          >
            <v-icon left dark> mdi-login </v-icon>
            Iniciar sessão
          </button>
        </v-col>
        <v-col cols="12" class="d-flex justify-center pa-1 mb-2">
          <v-btn @click="googleLogin" :loading="loading" text>
            <v-img
              contain
              width="18"
              class="mr-3"
              :src="require('../../assets/images/google-logo.svg')"
            ></v-img>
            Entrar com Google
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="shrink py-0">
          <v-btn
            x-small
            text
            :disabled="loading"
            @click="$emit('change-state', 1)"
          >
            Não possui cadastro?
          </v-btn>
        </v-col>
        <v-col class="shrink py-0">
          <v-btn
            x-small
            text
            :disabled="loading"
            @click="$emit('change-state', 2)"
          >
            Esqueceu a senha?
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar color="error" text v-model="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="error = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>
<script>
import { signIn, signInGoogle } from "../../plugins/auth";

export default {
  data: () => ({
    valid: false,
    email: "",
    password: "",
    loading: false,
    error: false,
    errorMessage: null,
    showPass: false,
  }),
  computed: {
    user: function () {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    async googleLogin() {
      await signInGoogle(this.successCallback, this.errorCallback);
    },
    async submit() {
      this.validate();
      if (!this.valid || this.loading) return;
      this.loading = true;
      this.errorMessage = null;
      this.error = false;
      await signIn(this.user, this.successCallback, this.errorCallback);
    },
    successCallback() {
      this.email = "";
      this.password = "";
      this.loading = false;
      location.reload();
      this.$router.push("/").catch(() => {});
    },
    errorCallback(error) {
      this.loading = false;
      this.errorMessage = error;
      this.error = true;
    },
    required(v) {
      return !!v || "Campo obrigatório";
    },
    emailRule(v) {
      return /.+@.+/.test(v) || "Email inválido";
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
