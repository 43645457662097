<template>
  <v-form v-model="valid" lazy-validation ref="form" @submit.prevent="submit">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            v-model="email"
            :rules="[required(email), emailRule(email)]"
            :disabled="loading"
            placeholder="Email"
            prepend-icon="mdi-at"
            required
            filled
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="password"
            :rules="[required(password), minLength(password, 6)]"
            :disabled="loading"
            placeholder="Senha"
            prepend-icon="mdi-lock-outline"
            required
            dense
            filled
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="confirmPassword"
            :rules="[
              required(confirmPassword),
              confirmPasswordRule(confirmPassword),
            ]"
            :disabled="loading"
            placeholder="Confirme a senha"
            prepend-icon="mdi-lock-outline"
            required
            dense
            filled
            :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmPass ? 'text' : 'password'"
            @click:append="showConfirmPass = !showConfirmPass"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center my-4 pa-1">
          <button
            class="mudadores-btn block"
            @click="submit"
            :disabled="loading"
          >
            <v-icon left dark> mdi-head-plus-outline </v-icon>Cadastrar
          </button>
        </v-col>
        <v-col cols="12" class="d-flex justify-center pa-1">
          <v-btn @click="googleLogin" :loading="loading" text>
            <v-img
              contain
              width="18"
              class="mr-3"
              :src="require('../../assets/images/google-logo.svg')"
            ></v-img>
            Entrar com Google
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar text color="success" v-model="success">
      Usuário criado com sucesso.
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="success = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar text color="error" v-model="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="error = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>
<script>
import { signUp, signInGoogle } from "../../plugins/auth";

export default {
  data: () => ({
    valid: false,
    email: null,
    password: null,
    confirmPassword: null,
    loading: false,
    error: false,
    errorMessage: null,
    success: false,
    showPass: false,
    showConfirmPass: false,
  }),
  computed: {
    user: function () {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    async googleLogin() {
      await signInGoogle(this.successCallback, this.errorCallback);
    },
    async submit() {
      this.validate();
      if (!this.valid || this.loading) return;
      this.loading = true;
      this.errorMessage = null;
      this.error = false;
      await signUp(this.user, this.successCallback, this.errorCallback);
    },
    successCallback() {
      this.success = true;
      this.loading = false;
      location.reload();
      this.$router.push("/").catch(() => {});
    },
    errorCallback(error) {
      this.loading = false;
      this.errorMessage = error;
      this.error = true;
    },
    required(v) {
      return !!v || "Campo obrigatório";
    },
    minLength(v, min) {
      return (
        !v ||
        v.length >= min ||
        "Campo deve ter no mínimo " + min + " caracteres"
      );
    },
    emailRule(v) {
      return /.+@.+/.test(v) || "Email inválido";
    },
    confirmPasswordRule(v) {
      return v == this.password || "Senhas não são iguais";
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
